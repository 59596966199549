import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Button from "../components/form/button"
import Layout from "../components/layout"
import Head from "../components/header/head"
import Slider from "../components/slider/slider"
import Heading from "../components/form/heading"
import type { Post } from "../common/post.model"
import { type PostType, POST_TYPES } from "../common/post-type.model"

const HomePage = () => {
  const postsData = useStaticQuery(graphql`
    query {
      featuredSuhbahs: allContentfulPost(
        limit: 8
        sort: { fields: updatedAt, order: DESC }
        filter: { postType: { typeId: { eq: "SUHBAH" } }, featured: { eq: true } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
      featuredEnseignements: allContentfulPost(
        limit: 8
        sort: { fields: updatedAt, order: DESC }
        filter: { postType: { typeId: { eq: "ENSEIGNEMENT" } }, featured: { eq: true } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
      featuredArticles: allContentfulPost(
        limit: 8
        sort: { fields: updatedAt, order: DESC }
        filter: { postType: { typeId: { eq: "ARTICLE" } }, featured: { eq: true } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
      shouldKnowPosts: allContentfulPost(
        filter: { slug: { in: ["sayyidina-ali-selon-les-ahl-as-sunnah", "le-veritable-disciple", "le-premier-khatm-khawajagan"] } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
      postTypes: allContentfulPostType(limit: 1000) {
        edges {
          node {
            typeId
            name
            description
          }
        }
      }
    }
  `)

  const featuredSuhbahs: Post[] = postsData.featuredSuhbahs.edges.map((edge: any) => edge.node)
  const featuredEnseignements: Post[] = postsData.featuredEnseignements.edges.map((edge: any) => edge.node)
  const featuredArticles: Post[] = postsData.featuredArticles.edges.map((edge: any) => edge.node)
  const shouldKnowPosts: Post[] = postsData.shouldKnowPosts.edges.map((edge: any) => edge.node);
  const shouldKnowLinks = [{static: 'tariqa'}];
  const postTypes: PostType[] = postsData.postTypes.edges.map((edge: any) => edge.node)
  const suhbahType = postTypes.find(pt => pt.typeId === POST_TYPES.SUHBAH)
  const enseignementType = postTypes.find(pt => pt.typeId === POST_TYPES.ENSEIGNEMENT)
  const articleType = postTypes.find(pt => pt.typeId === POST_TYPES.ARTICLE)
  return (
    <Layout headerColor="" headerSubTitle="" headerTitle="" headerPageName="Home">
      <Head title="Home" description="La Voie des Maîtres est une plateforme qui a pour vocation de donner un accès aux enseignements des guides spirituels, les Murshid (Maîtres-éducateurs) – prononcés Mourshid - de la Voie Naqshbandi-Haqqani, en langue française." />
      { 
        featuredSuhbahs?.length ?  
          <div>
            <div className="flex flex-col md:flex-row-reverse">
              <div className="ml-auto">
                <Button color="green" size="medium">
                  <Link to="/suhbah">Plus de ṣuḥbah</Link>
                </Button>
              </div>
              <div className="mt-4 md:mt-0">
                <Heading color="yellow">Ṣuḥbah à la une :</Heading>
                <div className="text-gray-400 max-w-3xl mt-4">{suhbahType?.description}</div>
              </div>
            </div>
            <div className="mt-4">
              <Slider posts={featuredSuhbahs}></Slider>
            </div>
          </div>
        : <></>
      }
      { 
        featuredEnseignements?.length ?
          <div>
            <div className="flex flex-col md:flex-row-reverse mt-12">
              <div className="ml-auto">
                <Button color="green" size="large">
                  <Link to="/enseignements">Plus d'enseignements</Link>
                </Button>
              </div>
              <div className="mt-4 md:mt-0">
                <Heading color="yellow">Enseignements à la une :</Heading>
                <div className="text-gray-400 max-w-3xl mt-4">{enseignementType?.description}</div>
              </div>
            </div>
            <div className="mt-4">
              <Slider posts={featuredEnseignements}></Slider>
            </div>
          </div>
        : <></>
      }
      {
        featuredArticles?.length ? 
          <div>
            <div className="flex flex-col md:flex-row-reverse mt-12">
              <div className="ml-auto">
                <Button color="green" size="medium">
                  <Link to="/articles">Plus d'articles</Link>
                </Button>
              </div>
              <div className="mt-4 md:mt-0">
                <Heading>Articles à la une :</Heading>
                <div className="text-gray-400 max-w-3xl mt-4">{articleType?.description}</div>
              </div>
            </div>
            <div className="mt-4">
              <Slider posts={featuredArticles}></Slider>
            </div>
          </div> 
        : <></>
      }
      <div className="flex flex-col md:flex-row-reverse mt-20 py-8 border-t-4 border-yellow-500 rounded-full">
        <div className="mx-auto md:mt-0">
          <Heading color="yellow">À savoir</Heading>
        </div>
      </div>
      <div className="mt-4">
        <Slider posts={shouldKnowPosts} imageLinks={shouldKnowLinks}></Slider>
      </div>
      <div className="border-b-4 border-yellow-500 rounded-full h-24"></div>
    </Layout>
  )
}
export default HomePage
